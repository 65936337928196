import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { InDateFilter, ServicesInstallationsFilter } from 'src/app/interfaces/filter/data-filter.interface';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {
  // Date
  private dateFilterSubject: BehaviorSubject<InDateFilter> = new BehaviorSubject<InDateFilter>({
    start: null,
    end: null
  } as InDateFilter);
  public dateFilter: Observable<InDateFilter> = this.dateFilterSubject.asObservable();

  // services/installations technician filter
  private technicianFilterSubject: BehaviorSubject<ServicesInstallationsFilter> =
    new BehaviorSubject<ServicesInstallationsFilter>(new ServicesInstallationsFilter({}));
  public technicianFilter: Observable<ServicesInstallationsFilter> = this.technicianFilterSubject.asObservable();

  constructor(
    private datepipe: DatePipe
  ) { }

  // Date filter
  public get dateFilterValue(): InDateFilter {
    return this.dateFilterSubject.value;
  }
  public setDateFilter(data?: InDateFilter): void {
    if (!data) {
      const currentDate = new Date();
      const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      //const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
      const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(),  currentDate.getDate());

      data = {
        start: this.datepipe.transform(minDate, 'yyyy-MM-dd'),
        start_complete: minDate.toISOString(),
        end: this.datepipe.transform(maxDate, 'yyyy-MM-dd'),
        end_complete: maxDate.toISOString()
      } as InDateFilter;
    }
    this.dateFilterSubject.next(data);
  }

  // Services/Installations technician filter
  public get technicianFilterValue(): ServicesInstallationsFilter {
    return this.technicianFilterSubject.value;
  }
  public setTechnicianFilter(data: ServicesInstallationsFilter): void {
    this.technicianFilterSubject.next(data);
  }


  // Get time from given date
  getTimeFromDate(date: string): number {
    const result = new Date(
      Number(this.datepipe.transform(date, 'y')),
      Number(this.datepipe.transform(date, 'M')) - 1,
      Number(this.datepipe.transform(date, 'd'))
      ).getTime();

    return result;
  }
}
