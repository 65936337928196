import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterDataService } from 'src/app/core/service/data/filter-data.service';
import { InDateFilter, ServicesInstallationsFilter } from 'src/app/interfaces/filter/data-filter.interface';
import { AlertService } from 'src/app/services/utilities/alert.service';
import { FormValidatorService } from 'src/app/services/validators/form-validator.service';
import { WhiteSpaceValidator } from 'src/app/services/validators/no-whitespace-validator.service';
import { DateFilterComponent } from '../date-filter/date-filter.component';

@Component({
  selector: 'app-services-installations-filter',
  templateUrl: './services-installations-filter.component.html',
  styleUrls: ['./services-installations-filter.component.sass']
})
export class ServicesInstallationsFilterComponent implements OnInit, OnDestroy {
  dialogTitle = 'Filtros';
  rangeForm: FormGroup;

  // Whitespace validation
  wsValidation = {type: 'noWhiteSpace', message: 'No se permite ingresar solo espacios en blanco'};

  // Validation messages
  validationMessages = {
    date: [{ type: 'required', message: 'Debes seleccionar una fecha' }, this.wsValidation],
    technician: [{ type: 'required', message: 'Ingresa un valor' }]
  };

  dateRangeControl: FormControl = new FormControl('');
  dateFilter: InDateFilter;
  technicianFilter: ServicesInstallationsFilter;

  filterByTechnicalName = true;

  constructor(
    private dialogRef: MatDialogRef<DateFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private formService: FormValidatorService,
    private alertService: AlertService,
    private datepipe: DatePipe,
    private filterService: FilterDataService
  ) {
    this.filterByTechnicalName = this.data.filterByTechnicalName;
    this.dateFilter = this.filterService.dateFilterValue;
    this.technicianFilter = this.filterService.technicianFilterValue;
    this.rangeForm = this.createRangeForm();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.closeDialog();
  }

  createRangeForm(): FormGroup {
    return this.fb.group({
      date_filter: [this.technicianFilter.date_filter ? true : false, []],
      start: [{ value: (this.dateFilter.start_complete ? this.dateFilter.start_complete : ''),
      disabled: false}, []],
      end: [{ value: (this.dateFilter.end_complete ? this.dateFilter.end_complete : ''),
      disabled: false}, []],
      technician_filter: [this.technicianFilter.technician_filter, []],
      technician: [this.technicianFilter.technician_filter ? this.technicianFilter.technician : '', []]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  technicianChanged(value: any): void {
    if (value) {
      this.rangeForm.controls.technician.setValidators(Validators.required);
      if (!this.rangeForm.controls.date_filter.value) {
        this.dateRangeChanged(false);
      }
    } else {
      this.rangeForm.controls.technician.removeValidators(Validators.required);
      this.rangeForm.controls.technician.markAsUntouched();
      this.rangeForm.controls.technician.updateValueAndValidity({onlySelf: true});
    }
    this.rangeForm.updateValueAndValidity();
  }

  dateRangeChanged(value: any): void {
    if (value) {
      this.rangeForm.controls.start.setValidators([Validators.required, WhiteSpaceValidator.noWhiteSpace]);
      this.rangeForm.controls.end.setValidators([Validators.required, WhiteSpaceValidator.noWhiteSpace]);
    } else {
      this.rangeForm.controls.start.removeValidators(Validators.required);
      this.rangeForm.controls.end.removeValidators(Validators.required);

      this.rangeForm.controls.start.markAsUntouched();
      this.rangeForm.controls.end.markAsUntouched();

      this.rangeForm.controls.start.updateValueAndValidity({onlySelf: true});
      this.rangeForm.controls.end.updateValueAndValidity({onlySelf: true});
    }
    this.rangeForm.updateValueAndValidity();
  }

  save(): void {
    if (this.rangeForm.valid) {
      if (this.rangeForm.controls.date_filter.value && this.rangeForm.controls.start.value) {
        const data = this.rangeForm.value;
        this.dateFilter = {
          start: this.datepipe.transform(data.start, 'yyyy-MM-dd'),
          start_complete: data.start,
          end: this.datepipe.transform(data.end, 'yyyy-MM-dd'),
          end_complete: data.end
        };
        this.filterService.setDateFilter(this.dateFilter);
      }

      this.technicianFilter.date_filter = this.rangeForm.controls.date_filter.value;
      this.technicianFilter.technician_filter = this.rangeForm.controls.technician_filter.value;
      this.technicianFilter.technician = this.rangeForm.controls.technician_filter.value ?
      this.rangeForm.controls.technician.value : undefined;

      if (this.technicianFilter.technician_filter && !this.technicianFilter.date_filter) {
        this.filterService.setDateFilter({} as InDateFilter);
      } else if (!this.technicianFilter.technician_filter && !this.technicianFilter.date_filter) {
        this.filterService.setDateFilter();
      }

      this.filterService.setTechnicianFilter(this.technicianFilter);
      this.closeDialog();
    } else {
      this.formService.allFields(this.rangeForm);
      this.alertService.warningMessage('Datos incorrectos', 'Revisa la información ingresada');
    }
  }
}
