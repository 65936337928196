import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/service/auth.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { IUserAccessRes } from 'src/app/interfaces/user.interface';
import { UserDataService } from 'src/app/core/service/user-data.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  loginForm: FormGroup;
  submitted = false;
  error = '';
  hide = true;

  loginProgressBtn: MatProgressButtonOptions = {
    active: false,
    text: 'Iniciar sesión',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    customClass: 'login100-form-btn',
    mode: 'indeterminate'
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userDataService: UserDataService
  ) {
    super();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.minLength(5)]
      ],
      password: ['', Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  async login(): Promise<void> {
    this.loginProgressBtn.active = true;
    this.submitted = true;
    this.error = '';

    if (this.loginForm.invalid) {
      this.error = 'Usuario y contraseña inválido';
      this.loginProgressBtn.active = false;
    } else {
      this.authService.login(this.f.email.value, this.f.password.value)
      .subscribe({
        next: (result: IUserAccessRes) => {
          if (result.status) {
            const token = this.userDataService.currentUserValue.token;
            if (token) {
              this.router.navigate(['/dashboard/main']);
            }
          } else {
            this.error = result.msg;
          }
          this.loginProgressBtn.active = false;
        },
        error: (err: any) => {
          this.error = err ? err : 'Ocurrió un error, intenta de nuevo';
          this.loginProgressBtn.active = false;
        },
        complete: () => {
          this.loginProgressBtn.active = false;
        }
      });
    }
  }
}
