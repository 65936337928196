import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { IResetPassword, IUser, IUserAccessRes, IUserRes } from 'src/app/interfaces/user.interface';
import { UserDataService } from './user-data.service';
import { InGlobalRes } from '../models/globalRes.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API_URL = environment.apiUrl;
  API_URL_NO_PREFIX = environment.apiNoPrefix;
  route = '/users';

  constructor(
    private http: HttpClient,
    private userDataService: UserDataService
  ) { }

  login(email: string, password: string): Observable<IUserAccessRes> {
    return this.http.post<IUserAccessRes>(`${this.API_URL}${this.route}/login`, {
      email,
      password
    }).pipe (map ((data: IUserAccessRes) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('currentUser', JSON.stringify(data.data.user));
      // localStorage.setItem('token', JSON.stringify(data.data.accessToken.token));
      data.data.user.token = data.data.accessToken.token;
      this.userDataService.currentUserValue = data.data.user;
      this.userDataService.userViewsValue = data.data.views;
      return data;
    }));
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.get<IUserAccessRes>(`${this.API_URL}${this.route}/forgot-password/${email}?email=${email}`, {
    });
  }

  resetPassword(data: IResetPassword, email: string, signature: string): Observable<IUserRes> {
    const params = new HttpParams()
    .append('signature', signature);
    // .append('email', email);

    return this.http.post<IUserRes>(`${this.API_URL_NO_PREFIX}/reset-password/${email}`,
    data, { params });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.userDataService.currentUserValue = null;
    return of({ success: false });
  }
  
	verify(): Observable<InGlobalRes> {
		return this.http.post<InGlobalRes>(`${this.API_URL}${this.route}/verify`, {});
	}
}
