import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IUser } from 'src/app/interfaces/user.interface';
import { FormValidatorService } from 'src/app/services/validators/form-validator.service';
import { UserSignupService } from 'src/app/core/service/user-signup.service';
import { AlertService } from 'src/app/services/utilities/alert.service';
import { ZipCodeService } from 'src/app/services/zip_code.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  hide = true;
  chide = true;

  dataSource: IUser;

  // Validation messages
  validationMessages = {
    name: [{ type: 'required', message: 'Ingresa un nombre' }],
    last_name: [{ type: 'required', message: 'Campo requerido' }],
    role_id: [{ type: 'required', message: 'Campo requerido' }],
    email: [
      { type: 'required', message: 'Ingresa un correo' },
      { type: 'email', message: 'Ingresa un correo válido' },
    ],
    email_confirmation: [
      { type: 'required', message: 'Ingresa un correo' },
      { type: 'email', message: 'Ingresa un correo de confirmación válido' },
    ],
    password: [{ type: 'required', message: 'Ingresa una contraseña' }],
    password_confirmation: [{ type: 'required', message: 'Confirma la contraseña' }],
    phone:  [{ type: 'required', message: 'Campo requerido' }],
    technical_number:  [{ type: 'required', message: 'Campo requerido' }],
    active:  [{ type: 'required', message: 'Campo requerido' }],
    zip_code:  [{ type: 'required', message: 'Campo requerido' }],
    zip_code_id:  [{ type: 'required', message: 'Campo requerido' }],
    state:  [{ type: 'required', message: 'Campo requerido' }],
    city:  [{ type: 'required', message: 'Campo requerido' }],
    points:  [{ type: 'required', message: 'Campo requerido' }]
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private formValidatorService: FormValidatorService,
    private alertService: AlertService,
    private userSignUpService: UserSignupService,
    private zipCodeService: ZipCodeService
  ) {
    this.loginForm = this.createForm();
  }

  ngOnInit() {
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: [ '',
        [Validators.required, Validators.email, Validators.minLength(5)]
      ],
      email_confirmation: [ '',
        [Validators.required, Validators.email, Validators.minLength(5)]
      ],
      phone: ['', Validators.required],
      role_id: [3, Validators.required],
      technical_number: ['0000', ],
      active: [true, Validators.required],
      zip_code: ['', []],
      zip_code_id: ['', []],
      state: [{value: '', disabled: true}, [Validators.required]],
      city: [{value: '', disabled: true}, [Validators.required]],
      points: [0, Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onZipChange(): void {
    const zip = this.loginForm.controls.zip_code.value;
    if (zip.length === 5) {
      this.searchZipCode(zip);
    }
  }

  register() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.valid) {
      this.dataSource = this.loginForm.value;
      this.dataSource.state = this.loginForm.controls.state.value;
      this.dataSource.city = this.loginForm.controls.city.value;

      // this.router.navigate(['/dashboard/main']);
      this.signUp();
    } else {
      this.formValidatorService.allFields(this.loginForm);
      return;
    }
  }

  signUp(): void {
    this.userSignUpService.store(this.dataSource).toPromise().then(
      response => {
      if (response.status) {
        this.router.navigate(['/authentication/signin']);
        this.alertService.successDialog(response.msg);
      } else {
        this.alertService.errorMessage('Error', response.msg);
      }
    },
    error => {
      this.alertService.errorMessage('Error', error);
    });
  }

  searchZipCode(zip: number): void {
    this.zipCodeService.show(zip).toPromise().then(
      response => {
      if (response.status) {
        if (response.data.length > 0) {
          this.loginForm.controls.zip_code_id.setValue(undefined);
          this.loginForm.controls.state.setValue(response.data[0].d_state);
          this.loginForm.controls.city.setValue(response.data[0].d_county);
        }
      } else {
        this.alertService.errorMessage('Error', response.msg);
      }
    },
    error => {
      this.alertService.errorMessage('Error', error);
    });
  }

}
