export interface InDateFilter {
    start?: string;
    start_complete?: string;
    end?: string;
    end_complete?: string;
}

export class ServicesInstallationsFilter {
    'date_filter'?: boolean;
    'technician_filter'?: boolean;
    technician?: string;

    constructor(data) {
        {
            this.date_filter = data.date_filter  || false ;
            this.technician_filter = data.technician_filter || false ;
            this.technician =  data.technician || '' ;
        }
    }
}

