import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { AuthService } from 'src/app/core/service/auth.service';
import { AlertService } from 'src/app/services/utilities/alert.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.sass']
})
export class DeleteAccountComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;

  continueProgressBtn: MatProgressButtonOptions = {
    active: false,
    text: 'Enviar correo',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    customClass: 'login100-form-btn',
    mode: 'indeterminate'
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.minLength(5)]
      ]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  resetPassword() {
    this.continueProgressBtn.active = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.continueProgressBtn.active = false;
      return;
    } else {
      this.authService.forgotPassword(this.f.email.value)
      .subscribe({
        next: (result: any) => {
          if (result.status) {
            this.loginForm.controls.email.setValue('');
            this.router.navigate(['/authentication/signin']);
            this.alertService.infoDialog('Aviso', 'Te hemos enviado un correo para que puedas reestablecer tu contraseña');
          } else {
            this.alertService.errorMessage('Error', result.msg);
          }
        },
        error: (err: any) => {
          this.continueProgressBtn.active = false;
          this.alertService.errorMessage('Error', err);
        },
        complete: () => {
          this.continueProgressBtn.active = false;
        }
      });
    }
  }


}

