
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICityResA, IStateResA, ISuburbTypeResA, IZipCode, IZipCodeFiltered, IZipCodeRes, IZipCodeResA } from 'src/app/interfaces/zip_code.interface';

@Injectable({
  providedIn: 'root'
})
export class ZipCodeService {
	API_URL = environment.apiUrl;
	route = '/zip_codes';

	constructor(
		private http: HttpClient
	) { }

	get(): Observable<IZipCodeResA> {
		return this.http.get<IZipCodeResA>(`${this.API_URL}${this.route}`, {});
	}

	show(id: number): Observable<IZipCodeResA> {
		return this.http.get<IZipCodeResA>(`${this.API_URL}${this.route}/${id}?id=${id}`, {
		});
	}

	store(data: IZipCode): Observable<IZipCodeRes> {
		return this.http.post<IZipCodeRes>(`${this.API_URL}${this.route}`, {
		...data
		});
	}

	delete(id: number): Observable<IZipCodeRes> {
		return this.http.delete<IZipCodeRes>(`${this.API_URL}${this.route}/${id}`, {});
	}

	update(data: IZipCode): Observable<IZipCodeRes> {
		return this.http.put<IZipCodeRes>(`${this.API_URL}${this.route}/${data.id}`, {
			active: data.active
		});
	}

	getStates(): Observable<IStateResA> {
		return this.http.get<IStateResA>(`${this.API_URL}${this.route}/states`, {});
	}

	getCities(state: string): Observable<ICityResA> {
		return this.http.post<ICityResA>(`${this.API_URL}${this.route}/city/state`, {
			state
		});
	}

	getFiltered(filter: IZipCodeFiltered): Observable<IZipCodeResA> {
		return this.http.post<IZipCodeResA>(`${this.API_URL}${this.route}/filter`, {
			...filter
		});
	}

	getSuburbTypes(): Observable<ISuburbTypeResA> {
		return this.http.get<ISuburbTypeResA>(`${this.API_URL}${this.route}/suburb`, {
		});
	}
}
