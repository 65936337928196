  <div class="container-fluid p-0">
    <div class="row g-0">
      <div class="col-lg-4">
        <div class="auth-content p-4 d-flex align-items-center min-vh-100">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">
                    <div>
                      <a routerLink="" class="logo-full"><img src="assets/images/logo-full.png" alt="logo-full"></a>
                    </div>
                    <h4 class="font-20 mt-2">Crear nueva cuenta</h4>
                    <!-- <p class="text-muted"></p> -->
                  </div>
                  <div class="p-1 mt-0">
                    <mat-stepper orientation="vertical" [linear]="false" #stepper>
                      <mat-step [stepControl]="loginForm">
                        <ng-template matStepLabel>Datos personales</ng-template>
                        <form [formGroup]="loginForm" class="pt-3">
                          <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">face</mat-icon>
                            <input type="text" formControlName="name" class="form-control auth-control" id="name"
                              placeholder="Nombre"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                              <mat-error *ngFor="let validation of validationMessages.name">
                                <span *ngIf="formValidatorService.field(loginForm, validation.type, 'name')">
                                    {{ validation.message }}
                                </span>
                              </mat-error>
                          </div>
                          <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">face</mat-icon>
                            <input type="text" formControlName="last_name" class="form-control auth-control" id="last_name"
                              placeholder="Apellido"  [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                              <mat-error *ngFor="let validation of validationMessages.last_name">
                                <span *ngIf="formValidatorService.field(loginForm, validation.type, 'last_name')">
                                    {{ validation.message }}
                                </span>
                              </mat-error>
                          </div>
                          <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">phone</mat-icon>
                            <input type="text" formControlName="phone" class="form-control auth-control" id="phone"
                              placeholder="Teléfono" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                              <mat-error *ngFor="let validation of validationMessages.phone">
                                <span *ngIf="formValidatorService.field(loginForm, validation.type, 'phone')">
                                    {{ validation.message }}
                                </span>
                              </mat-error>
                          </div>
                          <div class="d-flex justify-content-end">
                            <button mat-button matStepperNext>Siguiente</button>
                          </div>
                        </form>
                      </mat-step>
                      <mat-step [stepControl]="loginForm">
                        <ng-template matStepLabel>Dirección</ng-template>
                        <form [formGroup]="loginForm" class="pt-3">
                            <div class="form-group auth-form-group mb-4">
                              <mat-icon matSuffix class="auth-page-icon">room</mat-icon>
                              <input formControlName="zip_code" class="form-control auth-control" id="zip_code" (input)="onZipChange()"
                                placeholder="Código Postal" [ngClass]="{ 'is-invalid': submitted && f.zip_code.errors }">
                                <mat-error *ngFor="let validation of validationMessages.zip_code">
                                  <span *ngIf="formValidatorService.field(loginForm, validation.type, 'zip_code')">
                                      {{ validation.message }}
                                  </span>
                                </mat-error>
                            </div>
                            <div class="form-group auth-form-group mb-4">
                              <mat-icon matSuffix class="auth-page-icon">room</mat-icon>
                              <input formControlName="state" class="form-control auth-control" id="state"
                                placeholder="Estado" [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                                <mat-error *ngFor="let validation of validationMessages.state">
                                  <span *ngIf="formValidatorService.field(loginForm, validation.type, 'state')">
                                      {{ validation.message }}
                                  </span>
                                </mat-error>
                            </div>
                            <div class="form-group auth-form-group mb-4">
                              <mat-icon matSuffix class="auth-page-icon">room</mat-icon>
                              <input formControlName="city" class="form-control"
                                id="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                placeholder="Ciudad" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"/>
                                <mat-error *ngFor="let validation of validationMessages.city">
                                  <span *ngIf="formValidatorService.field(loginForm, validation.type, 'city')">
                                      {{ validation.message }}
                                  </span>
                                </mat-error>
                            </div>
                          <div class="d-flex justify-content-end">
                            <button mat-button matStepperPrevious>Atrás</button>
                            <button mat-button matStepperNext>Siguiente</button>
                          </div>
                        </form>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>Cuenta</ng-template>
                        <form [formGroup]="loginForm" class="pt-3">
                          <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">email</mat-icon>
                            <input type="email" formControlName="email" class="form-control auth-control" id="email"
                              placeholder="Correo electrónico" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                              <mat-error *ngFor="let validation of validationMessages.email">
                                <span *ngIf="formValidatorService.field(loginForm, validation.type, 'email')">
                                    {{ validation.message }}
                                </span>
                              </mat-error>
                          </div>
                          <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">email</mat-icon>
                            <input type="email" formControlName="email_confirmation" class="form-control auth-control" id="email_confirmation"
                              placeholder="Confirma el correo electrónico" [ngClass]="{ 'is-invalid': submitted && f.email_confirmation.errors }">
                              <mat-error *ngFor="let validation of validationMessages.email_confirmation">
                                <span *ngIf="formValidatorService.field(loginForm, validation.type, 'email_confirmation')">
                                    {{ validation.message }}
                                </span>
                              </mat-error>
                          </div>
                          <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">vpn_key</mat-icon>
                            <input type="password" formControlName="password" class="form-control auth-control"
                              id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                              placeholder="Contraseña" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                              <mat-error *ngFor="let validation of validationMessages.password">
                                <span *ngIf="formValidatorService.field(loginForm, validation.type, 'password')">
                                    {{ validation.message }}
                                </span>
                              </mat-error>
                          </div>
                          <div class="form-group auth-form-group mb-4">
                            <mat-icon matSuffix class="auth-page-icon">vpn_key</mat-icon>
                            <input type="password" formControlName="password_confirmation" class="form-control auth-control"
                              id="password_confirmation" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }"
                              placeholder="Confirma tu contraseña" />
                              <mat-error *ngFor="let validation of validationMessages.password_confirmation">
                                <span *ngIf="formValidatorService.field(loginForm, validation.type, 'password_confirmation')">
                                    {{ validation.message }}
                                </span>
                              </mat-error>
                          </div>
                          <div class="d-flex justify-content-end">
                            <button mat-button matStepperPrevious>Atrás</button>
                          </div>
                        </form>
                      </mat-step>
                    </mat-stepper>
                    <div class="flex-sb-m w-full p-t-15 p-b-20">
                      <div>
                        <span>¿Ya estás registrado? <a routerLink="/authentication/signin">
                            Iniciar sesión
                          </a></span>
                      </div>
                    </div>

                    <div class="container-login100-form-btn">
                      <button mat-flat-button color="primary" class="login100-form-btn" (click)="register()">
                        Registrame
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="auth-bg">
          <div class="bg-overlay"></div>
        </div>
      </div>
    </div>
  </div>
