import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from 'src/app/interfaces/user.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSignupService {
  API_URL = environment.apiUrl;
	route = '/users/register';

	constructor(
		private http: HttpClient
	) { }

	store(data: IUser): Observable<any> {
		return this.http.post<any>(`${this.API_URL}${this.route}`, {
		...data
		});
	}
}
