  <div class="container-fluid p-0">
    <div class="row g-0">
      <div class="col-lg-4">
        <div class="auth-content p-4 d-flex align-items-center min-vh-100">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">
                    <div>
                      <a routerLink="" class="logo-full"><img src="assets/images/logo-full.png" alt="logo-full"></a>
                    </div>

                    <h4 class="font-20 mt-4">Bienvenido</h4>
                    <p class="text-muted">Inicia sesión para continuar</p>
                  </div>

                  <div class="p-2 mt-5">
                    <form [formGroup]="loginForm">
                      <div *ngIf="error" class="alert alert-danger mb-3 mt-0">{{error}}</div>
                      <div class="form-group auth-form-group mb-4">
                        <mat-icon matSuffix class="auth-page-icon" aria-label="">email</mat-icon>
                        <input type="email" formControlName="email" class="form-control auth-control" id="email"
                          placeholder="Correo electrónico" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Correo requerido</div>
                          <div *ngIf="f.email.errors.email">Ingresa un correo válido</div>
                        </div>
                      </div>

                      <div class="form-group auth-form-group mb-4">
                        <mat-icon matSuffix class="auth-page-icon" aria-label="">vpn_key</mat-icon>
                        <input type="password" formControlName="password" class="form-control auth-control"
                          id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                          placeholder="Contraseña" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Contraseña requerida</div>
                        </div>
                      </div>

                      <div class="flex-sb-m w-full justify-content-center p-t-15 p-b-20">
                        <a class="txt1" routerLink="/authentication/forgot-password">¿Olvidaste tu contraseña?</a>
                      </div>

                      <div class="container-login100-form-btn">
                        <mat-spinner-button 
                          ngClass="spinner-btn" 
                          style="width: 100%;"
                          [options]="loginProgressBtn"
                          [active]="loginProgressBtn.active"
                          (btnClick)="login()">
                        </mat-spinner-button>
                      </div>

                    </form>
                  </div>
                </div>

                <div class="mt-5 text-center">
                  <!-- <p>¿No tienes cuenta? <a routerLink="/authentication/signup"
                      class="font-weight-medium text-primary">
                      Registrame </a> 
                  </p> -->
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="auth-bg">
          <div class="bg-overlay"></div>
        </div>
      </div>
    </div>
  </div>
