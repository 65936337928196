import { LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';
import { QRCodeModule } from 'angularx-qrcode';

import { environment } from "src/environments/environment";

registerLocaleData(localeEsMx, 'es');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgbModule,
  NgxSpinnerModule,
  MaterialModule,
  FeatherIconsModule,
  ClickOutsideModule,
  PerfectScrollbarModule,
  NgxMatSelectSearchModule,
  NgxMaskModule.forRoot(),
  AgmCoreModule.forRoot({
    apiKey: environment.googleMaps.apiKey,
    libraries: ["places"]
  }),
  QRCodeModule
];

@NgModule({
  declarations: [],
  imports: [
    modules
  ],
  exports: [
    modules
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DatePipe
  ]
})
export class SharedModule {
  private path = "../../assets/images/svg-icons";

  constructor(
   private domSanitizer: DomSanitizer,
   public matIconRegistry: MatIconRegistry ) {
   this.matIconRegistry
   .addSvgIcon("lightbulb-on", this.setPath(`${this.path}/bulb-on.svg`))
   .addSvgIcon("lightbulb-off", this.setPath(`${this.path}/bulb-off.svg`))
   .addSvgIcon("lightbulb-intermitent", this.setPath(`${this.path}/bulb-intermitent.svg`));
  }
  private setPath(url: string): SafeResourceUrl {
   return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
