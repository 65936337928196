<h3 mat-dialog-title>{{dialogTitle}}</h3>
<mat-dialog-content>
    <form class="register-form m-4" [formGroup]="rangeForm" autocomplete="off">
        <div class="row">
            <div class="col-12 mb-2 p-0">
                <div class="col-12">
                    <mat-checkbox formControlName="technician_filter" (change)="technicianChanged($event.checked)">Buscar por {{filterByTechnicalName ? 'nombre/número de técnico' : 'número de técnico'}}</mat-checkbox>
                </div>
                <mat-form-field *ngIf="rangeForm.controls.technician_filter.value" class="col-12" appearance="outline">
                    <mat-label>{{filterByTechnicalName ? 'Nombre/Número de técnico' : 'Número de técnico'}}</mat-label>
                    <input matInput formControlName="technician">
                    <mat-error *ngFor="let validation of validationMessages.technician">
                      <span *ngIf="formService.field(rangeForm, validation.type, 'technician')">
                          {{ validation.message }}
                      </span>
                    </mat-error>
                </mat-form-field>    
                <div class="col-12">
                    <mat-checkbox formControlName="date_filter" (change)="dateRangeChanged($event.checked)">Rango de fecha</mat-checkbox>
                </div>
                <mat-form-field *ngIf="rangeForm.controls.date_filter.value" class="col-12" appearance="outline" (click)="rangePicker.open()">
                    <mat-label>Fecha</mat-label>
                    <mat-date-range-input [rangePicker]="rangePicker" [formGroup]="rangeForm">
                      <input matStartDate formControlName="start" placeholder="Fecha inicio" placeholder="DD/MM/YYYY" readonly>
                      <input matEndDate formControlName="end" placeholder="Fecha fin" placeholder="DD/MM/YYYY" readonly>
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker disabled="false">
                      <!-- <mat-date-range-picker-actions>
                        <button mat-button matDateRangePickerCancel>Cancelar</button>
                        <button mat-raised-button color="primary" matDateRangePickerApply>Aceptar</button>
                      </mat-date-range-picker-actions> -->
                    </mat-date-range-picker>
                    <mat-error *ngFor="let validation of validationMessages.date">
                      <span *ngIf="formService.field(rangeForm, validation.type, 'start')">
                          {{ validation.message }}
                      </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button color="primary" cdkFocusInitial (click)="save()">Aceptar</button>
</mat-dialog-actions>